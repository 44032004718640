import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import { setUser } from './store/auth'
import { setKategoriList } from './store/kategori'
import { setDompetList } from './store/dompet'
import { setToastData } from './store/toast'
import './App.css'

/**
 * Utils
 */
import setTheme from './utils/setTheme'

/**
 * component tanpa lazy load
 */
import LoadingPage from './components/global/LoadingPage'
import ToastComp from './components/global/ToastComp'
/**
 * Services
 */
import api from './services/api'
import dexieApi from './services/dexie'
import { setAllTransaksi, setListBulanDanTahun } from './store/transaksi'
import getListBulanAndTahun from './utils/getListBulanAndTahun'

function App() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const loadingOverlay = useSelector((state) => state.main.loadingOverlay)

  const setWidget = async () => {
    const { listBulanNew, listTahunNew } = await getListBulanAndTahun()
    listBulanNew.reverse()
    listTahunNew.reverse()
    dispatch(setListBulanDanTahun({
      listBulan: listBulanNew,
      listTahun: listTahunNew
    }))
  }

  /**
   * get data keuangan
   * 1. mengambil data keuangan, dompet, kategori dan budget
   * 2. simpan di indexed db
   * 3. masukkan ke state
   */
  const getDataKeuangan = async () => {
    const keuangan = await api.keuangan.index()
    const dompet = await api.dompet.index()
    const kategori = await api.kategori.index()
    const budget = await api.budget.index()

    dispatch(setAllTransaksi(keuangan.data.data))
    dispatch(setKategoriList(kategori.data.data))
    dispatch(setDompetList(dompet.data.data))

    await dexieApi.keu.storeMulti(keuangan.data.data)
    await dexieApi.dompet.storeMulti(dompet.data.data)
    await dexieApi.kategori.storeMulti(kategori.data.data)
    await dexieApi.budget.storeMulti(budget.data.data)

    await setWidget()

    setLoading(false)
    window.location = '/user'
  }

  /**
   * Skema inisialisasi data
   * - Cek data user login di indexed db. Jika sudah ada,
   *   artinya sudah inisialisasi data.
   * - j+Jika belum ada data user login di indexed db,
   *   get data user terlebih dahulu, lalu get data keuangan (keuangan, dompet, kategori, budget).
   *   Simpan semua di indexed db.
   */
  const initData = async () => {
    // // untuk development
    // api.auth.getUser({
    //   appName: 'keuangan'
    // }).then((res) => {
    //   if (res.data.status) {
    //     console.log(res.data)
    //   }
    // })
    const userLogin = await dexieApi.userLogin.index()
    if (userLogin.length > 0) {
      dispatch(setUser({
        user: userLogin[0].user,
        role: userLogin[0].role,
        current_role: userLogin[0].currentRole,
        keu_user: userLogin[0].keuUser
      }))

      const keuangan = await dexieApi.keu.index()
      const kategori = await dexieApi.kategori.index()
      const dompet = await dexieApi.dompet.index()

      dispatch(setAllTransaksi(keuangan))
      dispatch(setKategoriList(kategori))
      dispatch(setDompetList(dompet))

      await setWidget()

      setLoading(false)
    } else if (userLogin.length === 0 && navigator.onLine) {
      api.auth.getUser({
        appName: 'keuangan'
      }).then((res) => {
        if (res.data.status) {
          dispatch(setUser(res.data.data))
          dexieApi.userLogin.store(res.data.data)
          getDataKeuangan()
        } else {
          dispatch(setToastData({
            type: 'error',
            msg: res.data.message
          }))
        }
      })
        .catch(() => {
          setLoading(false)
        })
    } else if (!navigator.onLine) {
      setLoading(false)
    }
  }

  /**
   * use effect mounted
   * untuk init data dan set theme
   */
  useEffect(() => {
    initData()
    setTheme()
  }, [])

  return (
    <>
      {
        (!loading) ? (
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        ) : (
          <LoadingPage />
        )
      }
      <ToastComp />
      { (loadingOverlay) ? <LoadingPage /> : '' }
    </>
  )
}

export default App

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Error403 from '../components/global/Error403'

function AuthMiddleware({ children, abilities }) {
  const { user, currentRole } = useSelector((state) => state.auth)
  const location = useLocation()

  // check jika sudah login
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  // check jika current role sesuai hak akses untuk route
  let hasAbility = false
  if (abilities.includes(currentRole.role_slug)) {
    hasAbility = true
  }
  // const userAbilities = currentRole.abilities.split(',')
  // userAbilities.forEach((e) => {
  //   if (!hasAbility) {
  //     if (abilities.includes(e)) {
  //       hasAbility = true
  //     }
  //   }
  // })
  if (!hasAbility) {
    return <Error403 />
  }

  return children
}

AuthMiddleware.propTypes = {
  children: PropTypes.element.isRequired,
  abilities: PropTypes.oneOfType([PropTypes.string]).isRequired
}

export default AuthMiddleware
